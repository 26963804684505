import React from 'react';
import { Link } from 'react-router-dom';
import ROUTES from '../../navigations/Routes';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import DatePicker from 'react-datepicker';
function StudentRegister() {
  const navigate = useNavigate();
  const initialValues = {
    userName: '',
    email: '',
    dob: null,
    postalCode: '',
    password: '',
    confirmPassword: '',
  };
  const validationSchema = Yup.object().shape({
    userName: Yup.string().required(),
    email: Yup.string().required(),
    dob: Yup.date().required('Date of birth is Required!!'),
    postalCode: Yup.number().required('Required'),
    password: Yup.string().min(5).required(),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Required'),
  });
  const onSubmit = async (data) => {
    try {
      const response = await fetch(
        'https://csmserver.csmdrivingschool.ca/StudentRegister.php',
        {
          method: 'POST',

          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        }
      );
      if (response.ok) {
        console.log('IT WORKED');
        alert('Successfully Registered');
        navigate(ROUTES.login.name);
      } else {
        throw new Error('Network response was not ok.');
      }
    } catch (error) {
      console.error('Error while submitting', error);
    }
  };

  return (
    <div className="main">
      <div className="homeContainer">
        <div
          class="untree_co-hero inner-page container-fluid"
          style={{ backgroundImage: `url('/images/home.jpg')` }}
        >
          <div class="container-fluid">
            <div class="row align-items-center justify-content-center">
              <div class="col-12">
                <div class="row justify-content-center ">
                  <div class="col-lg-6 text-center ">
                    <h1
                      class="mt-5  heading text-white"
                      data-aos="fade-up"
                      data-aos-delay="100"
                    >
                      Student Register
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        <div class="untree_co-section logInBox">
          <div class="container-fluid">
            <div class="row mb-5 justify-content-center">
              <div
                class="col-lg-7 mx-auto order-1"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <Formik
                  initialValues={initialValues}
                  onSubmit={onSubmit}
                  validationSchema={validationSchema}
                >
                  <Form class="form-box">
                    <div class="row">
                      <div class="col-6 mb-3">
                        <label htmlFor="dob" className="date-placeholder">
                          Username
                        </label>
                        <Field
                          type="text"
                          className="form-control"
                          id="userName"
                          // placeholder="User Name"
                          autoComplete="off"
                          name="userName"
                        />
                        <ErrorMessage
                          name="userName"
                          component="span"
                          style={{ color: 'red' }}
                        ></ErrorMessage>
                      </div>

                      <div class="col-6 mb-3">
                        <label htmlFor="dob" className="date-placeholder">
                        Email
                        </label>
                        <Field
                          type="text"
                          className="form-control"
                          id="email"
                          // placeholder="Email"
                          autoComplete="off"
                          name="email"
                        />
                        <ErrorMessage
                          name="email"
                          component="span"
                          style={{ color: 'red' }}
                        ></ErrorMessage>
                      </div>
                      <div class="col-6 mb-3">
                      <label htmlFor="dob" className="date-placeholder">
                          Date of birth
                        </label>
                        <Field
                          type="date"
                          className="form-control"
                          id="dob"
                          name="dob"
                          autoComplete="off"
                        />
                        <ErrorMessage
                          name="dob"
                          component="span"
                          style={{ color: 'red' }}
                        ></ErrorMessage>
                      </div>
                      <div class="col-6 mb-3">
                      <label htmlFor="dob" className="date-placeholder">
                          Postal Code
                        </label>
                        <Field
                          type="text"
                          className="form-control"
                          id="postalCode"
                          // placeholder="Postal Code"
                          autoComplete="off"
                          name="postalCode"
                        />
                        <ErrorMessage
                          name="postalCode"
                          component="span"
                          style={{ color: 'red' }}
                        ></ErrorMessage>
                      </div>
                      <div class="col-6 mb-3">
                      <label htmlFor="dob" className="date-placeholder">
                          Password
                        </label>
                        <Field
                          type="password"
                          className="form-control"
                          id="password"
                          // placeholder="Password"
                          autoComplete="off"
                          name="password"
                        />
                        <ErrorMessage
                          name="password"
                          component="span"
                          style={{ color: 'red' }}
                        ></ErrorMessage>
                      </div>
                      <div class="col-6 mb-3">
                      <label htmlFor="dob" className="date-placeholder">
                      Confirm Password
                        </label>
                        <Field
                          type="password"
                          className="form-control"
                          id="confirmPassword"
                          // placeholder="Confirm Password"
                          autoComplete="off"
                          name="confirmPassword"
                        />
                        <ErrorMessage
                          name="confirmPassword"
                          component="span"
                          style={{ color: 'red' }}
                        ></ErrorMessage>
                      </div>
                      <div class="col-12 mb-1">
                        <label class="control control--checkbox">
                          <span class="caption">Remember me</span>
                          <input type="checkbox" checked="checked" />
                          <div class="control__indicator"></div>
                        </label>
                      </div>
                      <div class="col-12">
                        <button type="submit" class="btn btn-primary">
                          Register
                        </button>
                      </div>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default StudentRegister;
