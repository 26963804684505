import React from 'react';
import ROUTES from '../../navigations/Routes';
import { Link } from 'react-router-dom';
function GuideToDrivingTest() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 200,
      behavior: "smooth", // Smooth scrolling effect
    });
  };
  return (
    <div className="main">
      <div className="homeContainer">
        <div
          class="untree_co-hero inner-page container-fluid TheoryTest"
          style={{ height: 'auto' }}
        >
          <div class="row align-items-center justify-content-center">
            <div class="col-12" data-aos="fade-up" data-aos-delay="200">
              <div class="row refesherLesson">
                <div class="col-lg-6 mb-5">
                  <div
                    class="section-title mb-3"
                    data-aos="fade-up"
                    data-aos-delay="0"
                  >
                    <h2 class="my-4 text-black">Driving Knowledge Test</h2>
                  </div>
                  <h4
                    data-aos="fade-up"
                    data-aos-delay="100"
                    className=" text-black"
                    style={{ fontSize: 27 }}
                  >
                    Need to know
                  </h4>
                </div>
                <div class="col-lg-6" data-aos="fade-up" data-aos-delay="0">
                  <figure class="img-wrap">
                    <img
                      src="images/theoryTest.jpg"
                      alt="Image"
                      class="img-fluid"
                    //   width="400"
                    //height="400"
                    />
                    <div class="dotted"></div>
                  </figure>
                </div>
              </div>
              <div className="lessonContent">
                <h3>Guide To The Knowledge Test</h3>
                <p>
                  Knowlege test a written exams for drivers license applicants.

                </p>
                <p>
                  CSM Driving School provides an online practice tool for learners planning on acing their knowledge test in 1 try.
                </p>
                <p>
                  Usually lasting 20 to 30 minutes, each knowledge test consists
                  of two or three components (e.g. one segment on signs and one
                  section on rules of the road). To pass the test, you need to
                  have a total score of at least 80%. If you fail, you have the
                  option to retry after paying the charge. The validity of test
                  results is for a year. You only need to redo the portions of
                  the test that didn't satisfy MTO criteria if you retake it
                  within a year.
                </p>
                <p>
                  You will be tested on traffic laws and road signs in the knowledge exam for your G1 License. There will be two portions to the 40 multiple-choice questions: 20 will be about traffic signs, and 20 will be about road rules. To pass your G1 test, you need to properly answer 16 questions in each section.
                </p>
                {/* <h3>Do i have do any recommended Courses?</h3>
                <p>
                  The knowledge, abilities, and attitudes required to drive
                  safely and responsibly can also be acquired through an
                  MTO-approved Beginner Driver Education (BDE) course. These are
                  elective courses. But if you decide to sign up.
                </p> */}
                <h3>How should I prepare for the driving theory test?</h3>
                <p>
                  It's best to prepare for the Driving Theory Test by studying with CSM. CSM Driving School recommends at least 20-30 hours of practice before attempting the theory test.
                </p>
                <h3>What should I bring to the theory test?</h3>
                <p>
                  You must present{' '}
                  <a
                    href="https://drivetest.ca/licences/acceptable-id-documents/"
                    className="mr-1"
                  >
                    acceptable ID
                  </a>
                  at the time of your application.
                  <br />
                  You must meet medical requirments.
                  <br />
                  You must pay the{' '}
                  <a href="https://drivetest.ca/tests/fees/">
                    applicable fees.
                  </a>
                  <br />
                  You must pass a{' '}
                  <a href="https://drivetest.ca/tests/vision-tests/">
                    vision test
                  </a>
                  , a <Link to={ROUTES.theorytest.name}>knowledge test.</Link>
                </p>
                <h3>When do I get my theory test results?</h3>
                <p>
                  You'll get your results more or less straight away. You'll be
                  presented with a printed letter at the test centre, which will
                  confirm your score, and whether you've passed or failed.
                  <br />
                  This is valid for two years. Be sure to pass your practical
                  test during this time though. If it expires, you'll have to
                  take the theory test again.
                </p>
                <h3>What if I fail my theory test?</h3>
                <p>
                  If you fail, don't be downhearted. You can retake your test
                  same day or same time. And you'll have a better idea of what
                  to expect. Just make sure you get a bit of extra study in. And
                  good luck!
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="bookBox container-fluid"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <h3 className="text-white">Book your lessons online!</h3>
          <h5 className="text-white mt-3">
            Check availability and book your first lesson
          </h5>
          <Link
            onClick={scrollToTop}
            to={ROUTES.package.name}
            className="btn btn-primary btnPrice"
          >
            See Prices
          </Link>
        </div>
      </div>
    </div>
  );
}

export default GuideToDrivingTest;
