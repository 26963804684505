import React from 'react';
import { Link } from 'react-router-dom';
import ROUTES from '../../navigations/Routes';

function FemaleInstructor() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 200,
      behavior: "smooth", // Smooth scrolling effect
    });
  };
  return (
    <div className="main">
      <div className="homeContainer">
        <div
          class="untree_co-hero inner-page container-fluid TheoryTest"
          style={{ height: 'auto' }}
        >
          <div class="row">
            <div class="col-12" data-aos="fade-up" data-aos-delay="200">
              <div class="row refesherLesson">
                <div class="col-6 mb-5">
                  <div
                    class="section-title mb-3"
                    data-aos="fade-up"
                    data-aos-delay="0"
                  >
                    <h2 class="my-4 text-black">Female Driving Instructor</h2>
                  </div>
                  <h4
                    data-aos="fade-up"
                    data-aos-delay="100"
                    className=" text-black"
                    style={{ fontSize: 27 }}
                  >
                    Learn to Drive
                  </h4>
                  <button className="btn btn-primary mt-4 PriceG ">
                    Get Prices
                  </button>
                </div>
                <div class="col-6" data-aos="fade-up" data-aos-delay="0">
                  <figure class="img-wrap">
                    <img
                      src="images/femaleInstructor.png"
                      alt="Image"
                      class="img-fluid"
                      //   width="400"
                      //height="400"
                    />
                    <div class="dotted"></div>
                  </figure>
                </div>
              </div>
              {/*  */}
              <div
                class="justify-content-center"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <h4
                  className="text-black border-bottom"
                  style={{ fontSize: 35 }}
                >
                  Learning to drive with a female instructor
                </h4>
              </div>
              <div className="lessonContent">
                <h3>
                  What's the difference between a male and female instructor?
                </h3>
                <p>
                  There shouldn't really be any difference. Either should be
                  equally skilled, and all driving instructors are trained to be
                  patient and personable.
                </p>

                <h3>
                  Can I learn with a female driving instructor with CSM Driving
                  School?
                </h3>
                <p>
                  CSM does, of course, make every effort to accommodate students
                  who specifically request a female instructor. We acknowledge
                  that certain individuals could find it more comfortable to
                  learn from women; sometimes it's that easy.
                </p>

                <h3>
                  Why might someone feel obliged to learn with a female
                  instructor?
                </h3>
                <p>
                  There may be religious or cultural reasons for specifying a
                  gender.
                </p>
                <p>
                  For example, some women can find it difficult to spend time
                  alone with a male who isn't their spouse or a family member.
                  Then, by selecting a female driving teacher, this could be
                  seen. For the same reason, a man might have to select a male
                  tutor.
                </p>
                <h3>So should I choose a female instructor?</h3>
                <p>
                  That's your decision. If you feel you'd rather take lessons
                  with a female driving instructor, then we'll do our best to
                  assign you one. But rest assured that any instructor CSM
                  assigns to you will be kind, professional, and either
                  completely certified or just finishing off their training.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="bookBox container-fluid"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <h3 className="text-white">Book your lessons online!</h3>
          <h5 className="text-white mt-3">
            Check availability and book your first lesson
          </h5>
          <Link
            onClick={scrollToTop}
            to={ROUTES.package.name}
            className="btn btn-primary btnPrice"
          >
            See Prices
          </Link>
        </div>
      </div>
    </div>
  );
}

export default FemaleInstructor;
