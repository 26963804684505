import React from 'react';
import axios from 'axios';
import ROUTES from '../../navigations/Routes';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
function TestModule() {
  const [listOfItems, setListOfItems] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswers, setUserAnswers] = useState(
    Array(listOfItems.length).fill(null)
  );
  const [selectedOption, setSelectedOption] = useState(null);
  const [correctCount, setCorrectCount] = useState(0);
  const [incorrectCount, setIncorrectCount] = useState(0);
  const { id, userName } = useParams();
  const [loggedIn, setLoggedIn] = useState([]);
  const navigate = useNavigate();
  const [isAnswered, setIsAnswered] = useState(false);
  const [nonUserQuesAttempted, setNonUserQuesAttempted] = useState(0);
  // const location = useLocation();
  // const name = location.state.name;
  //
  const location = useLocation();
  useEffect(() => {
    const usr = localStorage.getItem('id');
    const nUserQuesAttmpt = parseInt(localStorage.getItem("nonUserQuesAttempted")) || 1;

    setNonUserQuesAttempted(nUserQuesAttmpt);
    setLoggedIn(usr);
    console.log(usr);
  }, []);
  //
  const { testCategoryId } = location.state || {};


  const shuffleWithSeed = (array, seed) => {
    let shuffled = [...array];

    // Seed-based pseudo-random function
    const seededRandom = (seed) => {
      let x = Math.sin(seed) * 10000;
      return x - Math.floor(x);
    };

    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(seededRandom(seed * (i + 1)) * (i + 1)); // Ensure randomness is tied to the seed
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]]; // Swap elements
    }

    return shuffled;
  };


  useEffect(() => {
    const fetchAndShuffleQuestions = async () => {
      try {
        console.log('Module ID:', id);

        const response = await fetch(
          `https://csmserver.csmdrivingschool.ca/Questions.php?moduleId=${id}`
        );
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const questions = await response.json();

        let storedData = JSON.parse(localStorage.getItem("storedItems")) || [];
        let existingItem = storedData.find(item => item.id === id);
        let rnum;

        if (existingItem) {
          console.log("Found in localStorage:", existingItem);
          rnum = existingItem.rnum;
        } else {
          console.log("Not found in localStorage, generating new random number");
          rnum = Math.random();
          storedData.push({ id, rnum });
          localStorage.setItem("storedItems", JSON.stringify(storedData));
        }

        console.log("Random number used:", rnum);

        // Use our new seeded shuffle function
        const shuffledQuestions = shuffleWithSeed(questions, rnum);

        console.log("Shuffled questions:", shuffledQuestions);

        setListOfItems(shuffledQuestions);
        setUserAnswers(Array(shuffledQuestions.length).fill(null));

      } catch (error) {
        console.error('Error fetching questions:', error);
      }
    };

    fetchAndShuffleQuestions();
  }, [id]);


  useEffect(() => {
    // Reset isAnswered state when the current question index changes
    setIsAnswered(userAnswers[currentQuestionIndex] !== null);
  }, [currentQuestionIndex, userAnswers]);
  const handleNext = () => {
    if (currentQuestionIndex < listOfItems.length - 1) {
      const nextIndex = currentQuestionIndex + 1;
      setCurrentQuestionIndex(nextIndex);
      setSelectedOption(userAnswers[nextIndex]); // Restore the previously selected option
      setIsAnswered(userAnswers[nextIndex] !== null); // Ensure isAnswered is updated correctly
    }
  };

  const formatText = (name) => {
    if (!name) return "";

    // Remove extra spaces
    name = name.replace(/\s+/g, " ").trim();

    // Ensure "A." is correctly formatted
    name = name.replace(/^A\.?\s*/, "A. ");

    // Ensure first letter of the name is capitalized
    name = name.replace(/A\.\s+(\w)(\w*)/, (match, first, rest) => {
      return `A. ${first.toUpperCase()}${rest.toLowerCase()}`;
    });

    return name;
  };

  const handlePrev = () => {
    if (currentQuestionIndex > 0) {
      const prevIndex = currentQuestionIndex - 1;
      setCurrentQuestionIndex(prevIndex);
      setSelectedOption(userAnswers[prevIndex]); // Restore the previously selected option
    }
  };
  const handleOptionClick = (selectedOption) => {
    if (userAnswers[currentQuestionIndex] !== null) {
      return;
    }
    
    
    setNonUserQuesAttempted(nonUserQuesAttempted + 1);
    localStorage.setItem("nonUserQuesAttempted", nonUserQuesAttempted);

    const correctOption = listOfItems[currentQuestionIndex]?.correct;
    const isCorrect = selectedOption === correctOption;

    const newAnswers = [...userAnswers];
    newAnswers[currentQuestionIndex] = selectedOption;
    setUserAnswers(newAnswers);
    setSelectedOption(selectedOption);
    setIsAnswered(true); // Mark the question as answered

    setCorrectCount(
      (prevCorrectCount) => prevCorrectCount + (isCorrect ? 1 : 0)
    );
    setIncorrectCount(
      (prevIncorrectCount) => prevIncorrectCount + (isCorrect ? 0 : 1)
    );

    if (
      newAnswers.filter((answer) => answer !== null).length ===
      listOfItems.length
    ) {
      handleTestCompletion();
    }
  };

  const getOptionStyle = (option) => {
    if (selectedOption) {
      if (selectedOption === option) {
        return option === listOfItems[currentQuestionIndex]?.correct
          ? { backgroundColor: '#90D26D' }
          : { backgroundColor: '#FFB1B1' };
      }
      if (listOfItems[currentQuestionIndex]?.correct === option) {
        return { backgroundColor: '#90D26D' };
      }
    }
    return {};
  };

  const handleTestCompletion = async () => {
    const totalQuestions = listOfItems.length;
    const totalIncorrect = totalQuestions - correctCount;
    const testResult = totalIncorrect > 25 ? 'Fail' : 'Pass';
    const resultData = {
      userId: loggedIn,
      testCategoryId: testCategoryId,
      moduleid: id,
      correctans: correctCount,
      incorrectans: totalIncorrect,
      date: new Date().toISOString(),
      resultstatus: testResult,
    };
    console.log('Result Data:', resultData);

    try {
      const response = await fetch(
        'https://csmserver.csmdrivingschool.ca/TestResults.php',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(resultData),
        }
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const responseData = await response.json();
      console.log('Response:', responseData);
      navigate('/testResult', { state: { data: resultData } });
    } catch (error) {
      console.error('Error saving test result:', error);
    }
  };

  // .post('http://localhost:3001/testresult',

  return (
    <div className="main">
      <div className="homeContainer" style={{ textAlign: 'center' }}>
        {/* <h2>{loggedIn}</h2>
          <div>
            <h1>Test Component</h1>
            <p>Route ID: {id}</p>
            {testCategoryId ? (
              <p>Test Category ID: {testCategoryId}</p>
            ) : (
              <p>No Test Category ID found</p>
            )}
          </div> */}

        {nonUserQuesAttempted != 11 ? (
          <div className="mainBox d-flex justify-content-center">
            <div className="testBox">
              <h4 className="text-black mx-3">Your Progress</h4>
              <div className="tinyBox">
                <div className='tinyBoxWrap'>
                  {listOfItems.length > 0 &&
                    listOfItems.map((item, index) => (
                      <h5
                        className="mx-2"
                        key={index}
                        style={{
                          backgroundColor:
                            index < 10 // Only color the first 10 questions
                              ? userAnswers[index] === null
                                ? 'gray'
                                : userAnswers[index] === item.correct
                                  ? '#8DF4A9' // Green for correct answer
                                  : '#FF7C7C' // Red for incorrect answer
                              : 'gray', // Locked questions have gray background
                          color: 'white',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: '30px', // Set a fixed width
                          height: '30px', // Set a fixed height
                          borderRadius: '50%', // Make it circular
                        }}
                      >
                        {index < 10 ? (
                          index + 1
                        ) : (
                          // <img src="/images/lock.png" alt="Locked" width="17" height="17" />
                          <i class="fa-solid fa-lock " ></i>
                        )}
                      </h5>
                    ))}

                </div>
              </div>
              <div className="coin">
                <ul
                  className="d-flex justify-content-between px-4"
                  style={{
                    margin: 0,
                    width: 290,
                  }}
                >
                  <li className="correct">{correctCount} Correct</li>
                  <li className="incorrect">{incorrectCount} Incorrect</li>
                </ul>
              </div>
            </div>
            <div class="QContainer">
              <div class="row1">
                {listOfItems.length > 0 && (
                  <div className="qBox">
                    <div className="ImageContainer1">
                      <img
                        src={
                          listOfItems[currentQuestionIndex]?.image
                            ? `https://csmdrivingschool.ca/${listOfItems[currentQuestionIndex].image}`
                            : '/images/common.png'
                        }
                        alt="Image"
                      />
                    </div>
                    <div className="contentContainer5">
                      <h3 className='text-left'>Q: <span className='upper'>{listOfItems[currentQuestionIndex]?.question}</span></h3>
                      {['option1', 'option2', 'option3', 'option4'].map(
                        (option, index) => (
                          <div
                            key={index}
                            className="options"
                            onClick={() =>
                              handleOptionClick(
                                listOfItems[currentQuestionIndex]?.[option]
                              )
                            }
                            style={{
                              ...getOptionStyle(
                                listOfItems[currentQuestionIndex]?.[option]
                              ),
                              cursor:
                                userAnswers[currentQuestionIndex] !== null
                                  ? 'not-allowed'
                                  : 'pointer',
                            }}
                          >
                            <input
                              type="radio"
                              id={`radio${index + 1}`}
                              name="select1"
                              disabled={
                                userAnswers[currentQuestionIndex] !== null
                              }
                            />
                            <label className='upper' htmlFor={`radio${index + 1}`}>
                              <p>

                                {
                                  formatText(
                                    listOfItems[currentQuestionIndex]?.[option]
                                  )
                                }

                              </p>
                              {/* <p>{listOfItems[currentQuestionIndex]?.[option]}</p> */}
                              {selectedOption ===
                                listOfItems[currentQuestionIndex]?.[option] && (
                                  <p>
                                    {listOfItems[currentQuestionIndex]?.explanation}
                                  </p>
                                )}
                            </label>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                )}

                <div class="buttonContainer">
                  <button
                    class="btn btn-outline-primary "
                    onClick={handlePrev}
                    disabled={currentQuestionIndex === 0}
                  >
                    prev
                  </button>
                  <button
                    class="btn btn-outline-primary"
                    onClick={handleNext}
                    // disabled={currentQuestionIndex === listOfItems.length - 1}
                    disabled={!isAnswered || currentQuestionIndex >= 9}
                  >
                    {' '}
                    next
                  </button>
                </div>
                {currentQuestionIndex >= 9 && (
                  <div className="popupNotification">
                    <p>You are about to attempt 10 free questions, now please register to proceed.</p>
                    <button className="registerButton" onClick={() => window.location.href = '/studentregister'}>
                      Register
                    </button>
                  </div>
                )}

              </div>
            </div>
          </div>) :
          (

            <div className='container py-5 '>
              <div className='quesEnd p-5 text-c w-100'>
                <h3 className=''>
                  You have attempted 10 questions. Please log in and buy the course to continue.
                </h3>
                <p>
                  You have attempted 10 questions, and your free limit has ended. To continue, please log in or register and purchase the course. Unlock full access to premium learning materials. Enhance your skills without any interruptions. Don't miss out on valuable content and insights. Upgrade now and take your learning to the next level!
                </p>
                <div className='col-12 text-c mt-4'>
                  <Link to={ROUTES.login.name} className="btn btn-primary mx-2">Login</Link>
                  <Link to={ROUTES.studentregister.name} className="btn btn-primary mx-2">Register</Link>
                </div>
              </div>
            </div>
          )
        }

      </div>
    </div>

  );
}

export default TestModule;