import React from 'react';
import { Link } from 'react-router-dom';
import ROUTES from '../../navigations/Routes';
import { useParams, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import TestModule from './TestModule';
function Test() {



  const { id } = useParams();
  const [listOfItems, setListOfItems] = useState([]);
  const [testCategories, setTestCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [loggedIn, setLoggedIn] = useState('');
  const [selectedModule, setSelectedModule] = useState(null);
  const [questions, setQuestions] = useState([]);
  //
  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const queryParams = useQuery();
  const location = useLocation();
  const { testCategoryId } = location.state || {};
  // const { state } = useLocation();
  // const testObject = state && state.testObject;

  const handleModuleClick = (moduleId, name) => {
    setSelectedModule({ id: moduleId, name: name });
    console.log('Selected Module:', { id: moduleId, name: name }); // Log selected module here
  };

  useEffect(() => {
    const usr = localStorage.getItem('id');
    setLoggedIn(usr);
    console.log(usr);
    console.log(testCategoryId);
  }, []);
  const onNavigate = (value) => {
    const usr = localStorage.getItem('id');
    setLoggedIn(usr);
    localStorage.setItem('id', usr);
    console.log(value.id);
  };
  useEffect(() => {
    console.log('categoryid:', id);

    if (id !== undefined) {
      fetch('https://csmserver.csmdrivingschool.ca/TheoryTestCategories.php')
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error('Network response was not ok.');
          }
        })
        .then((data) => {
          setSelectedCategory(data);
          console.log(id);
        })
        .catch((error) => {
          console.error('Error fetching category data:', error);
        });
    }
  }, [id]);

  useEffect(() => {
    if (id !== undefined) {
      // fetch(`http://localhost:3001/module/modulesByCategory/${id}`)
      fetch(`https://csmserver.csmdrivingschool.ca/Module.php?categoryId=${id}`)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error('Network response was not ok.');
          }
        })
        .then((data) => {
          setListOfItems(data);
          console.log(data);
        })
        .catch((error) => {
          console.error('Error fetching modules:', error);
        });
    }
  }, [id]);

  useEffect(() => {
    if (selectedModule) {
      //  fetch(`http://localhost:3001/questions/testQuestions/${id}`)
      fetch(
        `https://csmserver.csmdrivingschool.ca/Questions.php?moduleId=${id}`
      )
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error('Network response was not ok.');
          }
        })
        .then((data) => {
          setQuestions(data);
          console.log(data);
        })
        .catch((error) => {
          console.error('Error fetching questions:', error);
        });
    }
  }, [selectedModule]);

  return (
    <div className="main">
      <div className="homeContainer">
        {/* <div>
          <h1>Test Component</h1>
          <p>Route ID: {id}</p>
          {testCategoryId ? (
            <p>Test Category ID: {testCategoryId}</p>
          ) : (
            <p>No Test Category ID found</p>
          )}
        </div> */}
        <div>
          {selectedCategory && (
            <h2 className="py-5 text-center">
              {/* Check if id and categoryname are accessible */}
              {selectedCategory.id} {selectedCategory.categoryname}
            </h2>
          )}

          {/* Log selectedCategory to check its structure */}
        </div>

        <div className="untree_co-section" style={{ marginTop: 10 }}>
          <div className="container-fluid">
            <div className="row d-flex justify-content-center align-items-stretch instructorPanel">
              {listOfItems.map((value, key) => {
                return (
                  <div
                    className="col-sm-6 col-md-6 col-lg-5 my-4"
                    data-aos="fade-up"
                    data-aos-delay="0"
                    key={value.id}
                  >
                    <Link
                      to={`/testModule/${value.id}`}
                      state={{ testCategoryId }}
                      className="category d-flex align-items-start h-100"
                      onClick={() => handleModuleClick(value.id, value.name)}
                    >
                      <div className="instructorIcon">
                        <i class="bi bi-journal-album"></i>
                      </div>
                      <div>
                        <h3 style={{ fontSize: 26 }}>{value.name}</h3>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Test;
