import React from 'react';

function Terms() {
    return (
        <div className="main">
            <div className="untree_co-section col-lg-12 ">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12" data-aos="fade-up" data-aos-delay="100">
                            <div className="feature">
                                <div className="row justify-content-center pb-5 font-s">
                                    <div
                                        className="col-lg-7 text-center textTAC"
                                        data-aos="fade-up"
                                        data-aos-delay="0"
                                    >
                                        <h2 className="line-bottom text-center my-4">
                                            CSM Terms and Conditions
                                        </h2>
                                        <h4>
                                            MTO . ( Ministry of Transportation Ontario) Approved Driving Instructor Training Agreement
                                        </h4>




                                        <div className="mainP">
                                            <h4>1. DEFINITIONS</h4>

                                            <p className=''>
                                                1.1 In this Agreement the following expressions will have the following meanings unless inconsistent with the context:
                                            </p>
                                            <p className=''>

                                                <p className=''>
                                                    1.1.1 “Advance Payment” means the option for paying the Charges for all three Parts together in advance, as described in paragraph 7.2.1;
                                                </p>
                                                <p className=''>
                                                    1.1.2 “ADI OR DI” means a Approved Driving Instructor who has been tested and registered as an Approved Driving Instructor by the MTO or DriveTest.;
                                                </p>
                                                <p className=''>

                                                    1.1.3 “Business Day(s)” means days are standard weekdays excluding  a Saturday, Sunday or a public holiday.
                                                </p>
                                                <p className=''>

                                                    1.1.4 “Charges” means the fees and charges agreed and payable to Us in respect of the Services for Part 1, Part 2 and/or Part 3 whether paid by Advance Payment or Payment Plan;
                                                </p>
                                                <p className=''>

                                                    1.1.5 “Discount” means any discretionary discount off the Charges for Part 3 that we may have agreed with You on entry of this Agreement, as recorded in the confirmation receipt issued by Us to You;
                                                </p>
                                                <p className=''>

                                                    1.1.6 “ADI ” means the Designated Driving Instructor Trainer You will be allocated from time to time (the ADI OR DIT allocated may change at any time during the duration of this Agreement);
                                                </p>
                                                <p className=''>

                                                    1.1.7 “DIT” means Driving Instructor Training;
                                                </p>
                                                <p className=''>

                                                    1.1.8 “MTO ” means the Ministry of Transportation Ontario Or DriveTest.means  DriveTest: Licensed by the Government of Ontario
                                                    .
                                                </p>
                                                <p className=''>

                                                    1.1.9 “Part 1”, “Part 2”and “Part 3” means the relevant part of the process specified by the MTO or DriveTest. to be undertaken by persons wishing to qualify as an ADI, being Theory and Hazard Perception (Part 1), Driving Ability (Part 2), Teaching Ability (Part 3) and “Part” or “Parts” shall be construed as meaning any one or more of these parts as appropriate;
                                                </p>
                                                <p className=''>

                                                    1.1.10 “Payment Plan” means the option for paying the Charges under an instalment plan, as described in paragraph 7.2.2;
                                                </p>
                                                <p className=''>

                                                    1.1.11 “Services” means the services which We provide to You (including any of them or any part of them) under this Agreement;
                                                </p>
                                                <p className=''>

                                                    1.1.12 “We/Us/Our/CSM ” means CSM( Canadian School of Motoring) Inc Business number 796149557RC0001 and HST number:************.;
                                                </p>
                                                <p className=''>

                                                    1.1.13 “You/Your” means the pupil or Students  receiving driving instructor training under the terms and conditions of this Agreement.
                                                </p>
                                            </p>
                                            <p className=''>
                                                1.2 The Driving Instructors  provided under this Agreement are provided for the purposes of Your trade, profession or business and your status as a customer will be interpreted accordingly.
                                            </p>

                                        </div>


                                        <div className="mainP">
                                            <h4>2. MTO or DriveTest.  ACCEPTANCE AND OTHER PRE-CONDITIONS OF TRAINING</h4>

                                            <p className=''>
                                                2.1 Our obligations under this Agreement will not begin until such time as Your application to start qualifying as an DI has been accepted by the MTO or DriveTest.
                                            </p>
                                            <p className=''>
                                                2.2 You confirm that:
                                            </p>
                                            <p className=''>
                                                <p className=''>
                                                    2.2.1 (i) You do not have any outstanding criminal charges and (ii) You do not have any existing criminal convictions, or You have had the required criminal records check approved by the MTO or DriveTest.;
                                                </p>
                                                <p className=''>
                                                    2.2.2 You hold a clean, current and full driving licence;
                                                </p>
                                                <p className=''>
                                                    2.2.3 You are medically fit to drive;
                                                </p>
                                                <p className=''>
                                                    2.2.4 Your eyesight meets the MTO or DriveTest. or DriveTest standard; and
                                                </p>
                                                <p className=''>
                                                    2.2.5 You will notify Us of any change to Your circumstances which cause any of these statements in paragraph
                                                </p>
                                            </p>
                                            <p className=''>
                                                2.2 to be incorrect.
                                            </p>
                                            <p className=''>
                                                2.3 If any of the information set out in paragraph 2.2 above is incorrect or ceases at any time to be correct, or if any other information which You have provided to Us and which we have relied on in deciding to enter into this Agreement is incorrect or ceases, at any time, to be correct We may terminate this Agreement. For the consequences of termination see paragraph 11 below.
                                            </p>
                                            <p className=''>
                                                2.4 We may, at Our discretion, agree to provide the Services to You before the MTO or DriveTest. accepts Your application to start the qualifying process to become an ADI. If we do so and the MTO or DriveTest. does not accept Your application within a reasonable period of time, We may terminate this Agreement. For the consequences of termination see paragraph 11 below.
                                            </p>

                                        </div>

                                        <div className="mainP">
                                            <h4>3. BOOKING TUITION, KEEPING TO DriveTest TIME LIMITS, TESTS AND CSM Driving School Instructors OR ADDITIONAL TRAINING</h4>

                                            <p className=''>
                                                3.1 Once You have made any initial payment due to Us in relation to the Charges, We will give You details of how contact will be made between You and Your DI. We will try to allocate an ADI OR DIT in a location convenient to You, but this depends on availability.
                                            </p>
                                            <p className=''>
                                                3.2 It is Your responsibility to arrange:
                                            </p>

                                            <p className=''>
                                                <p className=''>
                                                    3.2.1 support for Part 1; and
                                                </p>
                                                <p className=''>
                                                    3.2.2 tuition for Parts 2 and/or 3 directly with Your DIT.
                                                </p>
                                            </p>
                                            <p className=''>
                                                3.3 We will use reasonable endeavours to fulfil any in-car tuition You have booked.
                                            </p>
                                            <p className=''>
                                                3.4 You will need to arrange Your Part 1 test with the MTO or DriveTest. and You must pay the fee for the Part 1 test directly to the MTO or DriveTest.. We may provide You with advice as to whether and when, in Our opinion, You are ready to take Your Part 1 test. However, if We recommend that You are ready to take Your Part 1 test, We do not give any guarantee that You will successfully pass such test.
                                            </p>
                                            <p className=''>
                                                3.5 Should You need to re-sit Your Part 1 test, You will be responsible for paying any applicable fees directly to the DriveTest and for arranging to re-sit Your Part 1 test with the DriveTest centre.
                                            </p>
                                            <p className=''>
                                                3.6 You will need to arrange Your Part 2 and/or Part 3 tests with the DriveTest centre. and You must pay the fee for the tests directly to the DriveTest centre.. As at the date of this Agreement, the DriveTest centre. requires You to pass both Your Part 2 and 3 tests within two (2) years of passing Your Part 1 test. It is Your responsibility to ensure that You arrange Your Part 2 and/or Part 3 in car tuition in a timely manner so as to enable You to meet this requirement (or any variation of it). We may provide You with advice as to whether and when, in Our opinion, You are ready to take Your Part 2 and/or Part 3 test. However, if We advise that You are ready to take Your test, We do not give any guarantee that You will successfully pass such a test.
                                            </p>
                                            <p className=''>
                                                3.7 It is Your responsibility to meet any DriveTest centre and MTO or DriveTest.. requirement(s) with regard to attendance at, and participation in, any relevant test including, but not limited to, any requirement to arrange and be accompanied by a pupil during the Part 3 test.
                                            </p>
                                            <p className=''>
                                                3.8 If We provide a vehicle for You to use for Your test(s), You will use the vehicle solely for this purpose and You will be responsible for meeting any costs We incur as a result of any damage sustained to the vehicle or any accident involving the vehicle whilst in Your custody which is not met by Our insurance policy. We reserve the right to refuse to provide or procure a vehicle for Your use in a Part 2 test where, in Our reasonable opinion or that of Your ADI OR DIT, Your driving is unsafe. In addition, We reserve the right to refuse to provide or procure a vehicle for Your use for a Part 3 test where, in Our reasonable opinion or that of Your ADI OR DIT, You have not reached a minimum standard of competence and cannot safely manage or control the driving of a pupil.
                                            </p>
                                            <p className=''>
                                                3.9 Should You need to re-sit Your Part 2 and/or Part 3 test, or need to take a test which has had to be rescheduled due to a failure to meet any test requirement as per paragraphs 3.6 and 3.7 above, You will be responsible for paying any applicable fees directly to the MTO or DriveTest. and for arranging to resit or re-schedule the test with the MTO or DriveTest.
                                            </p>
                                            <p className=''>
                                                3.10 We will normally provide You with a vehicle for the purpose of taking or re-sitting Your Part 2 or Part 3 test upon Your request and, unless You use part of Your in-car tuition allowance for any such test (at a rate of 2 hours per test) under paragraph 5.3 or, as applicable, 6.3 below, We will be entitled to charge You for such use at Our then current rates in accordance with paragraph
                                            </p>
                                            <p className=''>
                                                3.11 below. Your use of such vehicle shall be in accordance with paragraph 3.8 above. 3.11 Upon Your request, We may arrange for You to be provided with additional training over and above that included within the relevant Part. You will be obliged to pay Us for such additional training at Our then current rate(s), available from Us on request. All additional training must be paid for by You in advance to Us, together with HST at the rate applicable at the date of payment and must not be paid direct to Your ADI OR DIT.
                                            </p>
                                            <p className=''>
                                                3.12 Whilst not obliged to do so, we ask that You to advise Us as soon as possible of the outcome of every test attempt (whether You have passed or failed). It would also be helpful to Us if You could provide a full copy of Your test results to Us as these assist Us In identifying areas for development.
                                            </p>

                                        </div>
                                        <div className="mainP">
                                            <h4>4 PART 1 – THEORY & HAZARD PERCEPTION </h4>

                                            <p className=''>
                                                4.1 On or before the date of this Agreement (which shall be the date set out in the confirmation receipt We send to You), and prior to commencement of any Part 1 training under this paragraph 4, You will pay to Us the relevant Charges in accordance with paragraph 7.2 below. We will not be obliged to provide You with any Part 1 materials or training until We have received the agreed payment from You.
                                            </p>
                                            <p className=''>
                                                4.2 The standards which You will need to achieve to pass Part 1 are specified by the MTO or DriveTest. or DriveTest. In relation to Part 1 We will, subject to the terms of this Agreement, including without limitation paragraph 9 below, provide You with the following in order to assist You to seek to achieve those standards:
                                            </p>
                                            <p className=''>
                                                <p className=''>
                                                    4.2.1 access to online platforms and written training materials;
                                                </p>
                                                <p className=''>
                                                    4.2.2 administrative support; and
                                                </p>
                                                <p className=''>
                                                    4.2.3 two (2) hours of one to one support available with Your ADI OR DIT
                                                </p>
                                                <p className=''>
                                                    4.2.4 Such other training tools and/or assistance as We may from time to time consider appropriate and subject to such terms and conditions and limitations on availability as We may, at Our discretion, notify from time to time (and which may be withdrawn or amended from time to time).
                                                </p>
                                            </p>
                                        </div>
                                        <div className="mainP">
                                            <h4>5 PART 2 – DRIVING ABILITY </h4>

                                            <p className=''>
                                                5.1 You may at any time (but are not obliged to) request that Part 2 training be commenced. We are not obliged to provide Part 2 training until You have so requested and may require that You have passed Part 1 prior to commencement of Part 2. Prior to commencement of any Part 2 training under this paragraph 5 You will pay to Us the agreed Charges in accordance with paragraph 7.2 below. We will not be obliged to provide You with any Part 2 materials or training until We have received the relevant agreed payment from You.
                                            </p>
                                            <p className=''>
                                                5.2 The standards which You will need to achieve to pass Part 2 are specified by the MTO or DriveTest.. In relation to Part 2 We will provide You with the following in order to assist You to achieve those standards:
                                            </p>
                                            <p className=''>
                                                <p className=''>
                                                    5.2.1 access to online platforms and written training materials; and
                                                </p>
                                                <p className=''>
                                                    5.2.2 in-car tuition by a suitably qualified and experienced ADI OR DIT. We will provide the vehicle for this tuition; and
                                                </p>
                                                <p className=''>
                                                    5.2.3 subject to paragraphs 3.8 and 3.9 above, use of a vehicle for the purpose of taking Your Part 2 test; and
                                                </p>
                                                <p className=''>
                                                    5.2.4 Such other training tools and/or assistance as We may from time to time consider appropriate (and which may be withdrawn or amended from time to time).
                                                </p>
                                            </p>
                                            <p className=''>
                                                5.3 The maximum aggregate duration of the in-car tuition We are obliged to provide You with under paragraph 5.2.2 shall not exceed twelve (12) hours. If You want to use a vehicle supplied by Us to take a Part 2 test this will equate to two (2) hours of in-car tuition for each such test, see paragraphs 3.9 and 3.11 above for further details. If You fail to arrange for any of the in-car tuition which is available to You in relation to Part 2 to take place within 12 weeks of Your having passed the Part 1 test, we will be entitled, but not obliged, to terminate this Agreement
                                            </p>
                                        </div>

                                        <div className="mainP">
                                            <h4>6 PART 3 – TEACHING ABILITY  </h4>

                                            <p className=''>
                                                6.1 You may at any time (but are not obliged to) request that Part 3 training is commenced. We are not obliged to provide Part 3 training until You have so requested and may, at our discretion, require that You have passed Part 2 prior to commencement of Part 3. Prior to commencement of any Part 3 training under this paragraph 6 You will pay to Us the agreed Charges in accordance with paragraph 7.2 below. We will not be obliged to provide You with any Part 3 materials or training until We have received the relevant agreed payment from You.
                                            </p>
                                            <p className=''>
                                                6.2 The standards which You will need to achieve to pass Part 3 are specified by the MTO or DriveTest.. In relation to Part 3 We will provide You with the following in order to assist You to seek to achieve those standards:
                                            </p>
                                            <p className=''>
                                                <p className=''>
                                                    6.2 The standards which You will need to achieve to pass Part 3 are specified by the MTO or DriveTest.. In relation to Part 3 We will provide You with the following in order to assist You to seek to achieve those standards:
                                                </p>
                                                <p className=''>
                                                    6.2.2 in-car tuition by a suitably qualified and experienced ADI OR DIT. We will provide the vehicle for this tuition; and
                                                </p>
                                                <p className=''>
                                                    6.2.3 Use of a vehicle for the purpose of taking Your Part 3 test. You must use the vehicle in accordance with paragraph 3.7; and
                                                </p>
                                                <p className=''>
                                                    6.2.4 Such other training tools and/or assistance as We may from time to time consider appropriate (and which may be withdrawn or amended from time to time).
                                                </p>
                                            </p>
                                            <p className=''>
                                                6.3 The maximum aggregate duration of the in-car tuition We are obliged to provide You with under paragraph 6.2.2 shall not exceed thirty-six (36) hours. If You want to use a vehicle supplied by Us to take a Part 3 test this will equate to two (2) hours of in-car tuition for each such test, see paragraphs 3.9 and 3.11 above for further details. If You fail to arrange for any of the in-car tuition which is available to You in relation to Part 3 to take place within 12 weeks of Your having passed the Part 2 test we will be entitled, but not obliged, to terminate this Agreement.
                                            </p>
                                        </div>

                                        <div className="mainP">
                                            <h4>7 CHARGES AND PAYMENT  </h4>

                                            <p className=''>
                                                7.1 You must pay Us the Charges for Parts 1, 2 and 3 as agreed and set out on the confirmation receipt We send You.
                                            </p>
                                            <p className=''>
                                                7.2 We offer two payment options. The option that We have agreed with You will be shown on the confirmation receipt We send You. The two payment options (and some specific terms which apply to them) are as follows:
                                            </p>
                                            <p className=''>
                                                <p className=''>
                                                    7.2.1 Advance Payment – under this option You must pay all of the Charges for either (1) both Part 1 and Part 2 together or (2) all three Parts in advance as one lump sum before we start providing any of the Services;
                                                </p>
                                                <p className=''>
                                                    7.2.2 Payment Plan – under this option You must (1) pay an initial deposit and (2) provide a valid Direct Debit Instruction to pay future instalments on a monthly basis. The number of instalments and amounts payable will be set out on the confirmation receipt We send You confirming the agreed Payment Plan. We will only start providing Services after You have paid the deposit and are subject to You not falling behind in payment of the agreed instalments. You will be liable to pay Us a 20$ administration fee in each of the following circumstances:
                                                </p>
                                                <p className=''>
                                                    (a) for each and any instalment that is not, for whatever reason, paid when due for example, without limitation, because Our request for payment under Your Direct Debit mandate is returned unpaid by Your bank as a result of Your having insufficient funds in Your account; or
                                                </p>
                                                <p className=''>
                                                    (b) If You fail to set up or maintain Your Direct Debit Instruction (for example You tell Your bank to cancel the Direct Debit but You do not put in place a replacement Direct Debit).
                                                </p>
                                            </p>
                                            <p className=''>
                                                7.3 All Charges are inclusive of HST at the relevant prevailing rate from time to time. If the HST rate changes we will be entitled to adjust the Charges accordingly. We will notify You if this occurs.
                                            </p>
                                        </div>
                                        <div className="mainP">
                                            <h4>8 MATERIALS AND TUITION </h4>

                                            <p className=''>
                                                8.1 We will send the written training materials to Your nominated address. We will use reasonable endeavours to send the written training materials to You within 10 days after We receive from You the Charges for the relevant Parts (when paying by Advance Payment option) or after You have paid Your deposit (when paying under a Payment Plan).
                                            </p>
                                            <p className=''>
                                                8.2 If You cancel any in-car tuition in less than 48 hours’ notice any such tuition shall count towards the number of hours’ tuition specified in paragraphs 5.3 or, as applicable, 6.3. If You so cancel any tuition which You have requested be provided under paragraph 3.11 which is additional to Your entitlement under paragraphs 5.3 or, as applicable, 6.3, then We shall be entitled to retain or, as applicable, to recover the charges You have paid or which are due for that tuition.
                                            </p>

                                            <p className=''>
                                                8.3 In addition to the Services under this Agreement, We may provide suggestions on operating a franchise including business skills. The information is of a general nature, is not tailored and is not a substitute for professional advice. You are recommended to obtain specific professional advice before You take any action. We make no warranty of any kind with respect to the completeness or accuracy of the information provided. The provision by Us of any information, including but not limited to any application materials, relating to any franchise opportunity in no way guarantees that that opportunity will be available to You or that You will be eligible or accepted for any such opportunity. We will not be liable to You for any actions taken or any inaction as a result of You relying on or in any way using information given relating to operating a franchise and in no event shall We be liable to You for any damages resulting from reliance on or use of this information.
                                            </p>
                                        </div>
                                        <div className="mainP">
                                            <h4>9 TERMINATION AND SUSPENSION</h4>

                                            <p className=''>
                                                9.1 You have a right to terminate this Agreement if You fail any of the Part 1, Part 2 or Part 3 tests. However no refunds will be given.
                                            </p>
                                            <p className=''>
                                                9.2 Without prejudice If You are paying the Charges by instalments under a Payment Plan and You fail to make any instalment payment when due, and such instalment remains outstanding for a period of 7 days or longer, We will be entitled to suspend the Services until You pay us the missed instalment (and any other instalment then due, whether outstanding for 7 days or less). If any instalment remains outstanding for 60 days or longer or if You pay late or fail to make a payment when due on two or more occasions, we will be entitled to terminate this Agreement.
                                            </p>
                                            <p className=''>
                                                9.3 If neither We nor the ADI OR DIT receive contact from You for any period of 12 weeks or more We will be entitled to suspend the Services (including but not limited to access to any content sharing online platform under paragraphs 4.2.1, 5.2.1 and 6.2.1) until such time as You contact Us and request that Services are resumed.
                                            </p>
                                        </div>
                                        <div className="mainP">
                                            <h4>10 YOUR RIGHT TO CANCEL </h4>
                                            <p className=''>
                                                10.1 We will send You a pack of training materials together with a copy of this Agreement. You may cancel this Agreement during the 16-day period starting the day after the date of this Agreement as set out on the confirmation receipt We send to You (the “cooling off period”).
                                            </p>
                                            <p className=''>
                                                10.2 You may exercise this right to cancel the Agreement by informing Us of Your decision to cancel. You may do so by making a clear statement to Us of Your intention to cancel, within the cooling off period, by phone, post or e-mail. If You cancel You must return the training materials to Us, at Your own expense, to the return address which We will provide when sending You the pack referred to under paragraph 10.1 above.
                                            </p>
                                        </div>
                                        <div className="mainP">
                                            <h4>11 REFUNDS ON CANCELLATION OR TERMINATION OF THIS AGREEMENT </h4>
                                            <p className=''>
                                                11.1 If You cancel the Agreement within the 16-day cooling-off period as set out in paragraph 10, we will provide You a full refund of the Charges You have already paid to Us, on condition that You have:
                                            </p>
                                            <p className=''>
                                                <p className=''>
                                                    11.1.1 not accessed Our online platforms;
                                                </p>
                                                <p className=''>
                                                    11.1.2 not asked for nor received any training from Your ADI OR DIT; and
                                                </p>
                                                <p className=''>
                                                    11.1.3 returned any written training materials to Us unmarked and undamaged.
                                                </p>
                                            </p>
                                            <p className=''>
                                                11.2 After the cooling off period referred to in paragraph 10.1 You may cancel the Agreement but no refund will then be due from Us to You. After two (2) years of the date of the Agreement any training not already taken will be forfeited.
                                            </p>
                                            <p className=''>
                                                11.3 If We terminate the Agreement under paragraph 2.4 because the MTO or DriveTest. rejects your application to start qualifying as an ADI, We will provide a full refund.
                                            </p>
                                        </div>
                                        <div className="mainP">
                                            <h4>12. COPYRIGHT </h4>
                                            <p className=''>
                                                12.1 You agree and acknowledge that all rights in Our documents and materials is owned by or licensed to, and shall remain vested in or licensed to, Us or to the relevant member of Our group (as the case may be).
                                            </p>
                                            <p className=''>
                                                12.2 Any unauthorised copying, duplicating or distributing will constitute an infringement of copyright.
                                            </p>
                                        </div>
                                        <div className="mainP">
                                            <h4>13. GENERAL </h4>
                                            <p className=''>
                                                13.1 We are not liable for any death, loss or injury sustained by You during or after the tuition unless such death, loss or injury is due to Our negligence or breach of this Agreement.
                                            </p>
                                            <p className=''>
                                                13.2 If We or You choose not to rely on any of Our rights under this Agreement, this does not affect Our or Your liability to rely on these same rights in future.
                                            </p>
                                            <p className=''>
                                                13.3 We may assign, hold on trust, licence or sub-contract all or any part of Our rights or obligations under this Agreement. In particular, We may provide the Services (or any part of them) through third party driving instructors (who We may change from time to time).
                                            </p>
                                            <p className=''>
                                                13.4 This Agreement is personal to You and You may not assign; hold on trust; licence; sub-contract or re-sell all or any of Your rights or obligations under this Agreement without Our prior written consent.
                                            </p>
                                            <p className=''>
                                                13.5 No one else shall be able to enforce any of the terms of this Agreement.
                                            </p>
                                            <p className=''>
                                                13.6 This Agreement may not be varied unless that variation is in writing signed both by You and on behalf of Us.
                                            </p>
                                            <p className=''>
                                                13.7 Any notice in connection with this Agreement (including any complaints) must be in writing addressed to DIT Support Manager, CSM Driving School.
                                            </p>
                                            <p className=''>
                                                <p className=''>
                                                    13.7.1 if delivered by hand, when left at the proper address for service;
                                                </p>
                                                <p className=''>
                                                    13.7.2 if given or made by first class post or special delivery post, 48 hours after being posted or in the case of airmail 14 days after being posted excluding days other than Business Days.
                                                </p>
                                            </p>
                                            <p className=''>
                                                13.8 The formation, existence, construction, performance, validity and all aspects whatsoever of this Agreement will be governed by the relevant The Government of Ontario law.
                                            </p>
                                        </div>
                                        <div className="mainP">
                                            <h4>USE OF YOUR PERSONAL DATA</h4>
                                            <p className=''>
                                                This privacy notice does not form part of the terms and conditions and updates to it may be notified to you by us from time to time By using the contact details in your product terms and conditions or going to the Contact Us section of our website.
                                            </p>

                                        </div>
                                        <div className="mainP">
                                            <h4>1. What kinds of personal information about you do we process?</h4>
                                            <p className=''>
                                                Personal information that we’ll process in connection with all of our products and services, if relevant, includes:
                                                <ul>
                                                    <li>Personal and contact details, such as title, full name, contact details and contact details history; </li>
                                                    <li>Your date of birth, gender and/or age; </li>
                                                    <li>Your nationality, if needed for the product or service; </li>
                                                    <li>Details of beneficiaries, such as joint policy holders, named drivers, beneficiaries of our products or services; </li>
                                                    <li>Family members (if relevant to the product or service); </li>
                                                    <li>Records of your contact with us such as via the phone number of our breakdown service and, if you get in touch with us online using our online services or via our smartphone app, details such as your mobile phone location data, IP address and MAC address; </li>
                                                    <li>Products and services you hold with us, as well as have been interested in and have held and the associated payment methods used;</li>
                                                    <li>The usage of our products and services, any call outs and claims, and whether those claims were paid out or not (and details related to this); </li>
                                                    <li>Marketing to you and analysing data, including history of those communications, whether you open them or click on links, and information about products or services we think you may be interested in, and analysing data to help target offers to you that we think are of interest or relevance to you Offers may include our car, insurance, financial services, connected car, travel and any of our other products and services; </li>
                                                    <li>Vehicle information, such as make and model, faults, repairs and repair costs.</li>
                                                    <li>Telematics and driving information about your vehicle (including assessing and predicting faults or issues), driving style (including recommending improvements and assessing risk associated with your driving style), location and routes taken (for example, if you have Car Genie); </li>
                                                    <li>Driving school assessment, including feedback and analysis of your instructor;</li>
                                                    <li>Driving Instructor Training assessments, including any MTO or DriveTest. test results and feedback and analysis of your trainer; </li>
                                                    <li>Information about your use of products or services held with our business partners, such as insurance policies, mortgage, savings or financial services and products;</li>
                                                    <li>Information we obtained from third parties, including information about insurance risk, pricing, claims history, instances of suspected fraud and usage history; </li>
                                                    <li>Personal information which we obtain from Credit Reference Agencies and Fraud Prevention Agencies (see the section on ‘Fraud Prevention Agencies’ below), including public  and shared credit history, financial situation and financial history; </li>
                                                    <li>Fraud, debt and theft information, including details of money you owe, suspected instances fraud or theft, and details of any devices used for fraud; </li>
                                                    <li>Criminal records information, including alleged offences; </li>
                                                    <li>Information about your health or if you are a vulnerable customer; </li>
                                                    <li>Information about your property, such as location, value, number of rooms, property type and building work you’ve had done; </li>
                                                    <li>Financial details about you, such as your salary and details of other income, details of your savings, details of your expenditure, and payment method(s); </li>
                                                    <li>Details about all of your existing borrowings and loans, if relevant;</li>
                                                    <li>Information about your employment status, if relevant; </li>


                                                    <li>Information about your property occupier status, such as whether you are a tenant, live with parents or are an owner occupier of the property where you live at the time of your application; </li>
                                                    <li>Your residency and/or citizenship status, if relevant, such as your nationality, your length of residency in the Ontario (CA) and/or whether you have the permanent right to reside in Ontario (CA);</li>
                                                    <li>Your marital status, family, lifestyle or social circumstances, if relevant to the product (for example, the number of dependents you have or if you are a widow or widower); </li>
                                                    <li>Information we buy or rent from third parties, including demographic information, vehicle details, details of outstanding finance, vehicle claims history, marketing lists, publicly available information, and information to help improve the relevance of our products and services; </li>
                                                    <li>Insights about you and our customers gained from analysis or profiling of customers; </li>
                                                    <li>Where relevant, information about any guarantor which you provide in any application; </li>
                                                    <li>Third party transactions; such as where a person other than the account holder uses the service, information about that person and the transaction; and </li>

                                                </ul>
                                            </p>
                                        </div>
                                        <div className="mainP">
                                            <h4>2. What is the source of your personal information?</h4>
                                            <p>
                                                We’ll collect personal information from the following general sources:
                                                <ul>
                                                    <li>From you directly, and any information from family members, associates or beneficiaries of products and services;</li>
                                                    <li>Information generated about you when you use our products and services;</li>
                                                    <li>From a broker or other intermediary (e.g. comparison site) who we work with to provide products or services or quote to you;</li>
                                                    <li>CSM Driving School, if you already have a product with them, have applied for one or have held one previously;</li>
                                                    <li>Business partners (e.g. financial services institutions, insurers), account beneficiaries, or others who are a part of providing your products and services or operating our business;</li>
                                                    <li>From other sources such as Fraud Prevention Agencies, Credit Reference Agencies, publicly available directories and information (e.g. telephone directory, social media, internet, news articles), debt recovery and/or tracing agents, other organisations to assist in prevention and detection of crime, police and law enforcement agencies;</li>
                                                    <li>MTO or DriveTest (Ministry of Transportation Ontario);</li>
                                                    <li>The Disclosure and Barring Service;</li>
                                                    <li>We buy or rent information about you or customers generally from third parties, including demographic information, vehicle details, claims history, fraud information, marketing lists, publicly available information, and other information to help improve our products and services or our business.</li>
                                                </ul>
                                            </p>
                                        </div>
                                        <div className="mainP">
                                            <h4>3. What do we use your personal data for?</h4>
                                            <p>
                                                We use your personal data, including any of the personal data listed in section 1 above, for the following purposes:
                                                <ul>
                                                    <li>Assessing an application for a product or service you hold with us, including considering whether or not to offer you the product or service, the price, the risk of doing so, availability of payment method and the terms;</li>
                                                    <li>Managing products and services you have with us;</li>
                                                    <li>Updating your records, tracing your whereabouts, and recovering debt;</li>
                                                    <li>Managing any aspect of the product or service;</li>
                                                    <li>To make automated decisions on whether to offer you a product or service, or the price, payment method, risk or terms of it;</li>
                                                    <li>To perform and/or test the performance of our products, services and internal processes;</li>
                                                    <li>To improve the operation of our business and that of our business partners;</li>
                                                    <li>To follow guidance and best practice under the change to rules of governmental and regulatory bodies;</li>
                                                    <li>For management and auditing of our business operations including accounting;</li>
                                                    <li>To carry out checks at Credit Reference and Fraud Prevention Agencies pre-application, at application, and periodically after that;</li>
                                                    <li>To monitor and to keep records of our communications with you and our staff (see below);</li>
                                                    <li>To administer our good governance requirements and those of other members of our Group, such as internal reporting and compliance obligations or administration required for Annual General Meeting (“AGM”) processes;</li>
                                                    <li>For market research and analysis and developing statistics;</li>
                                                    <li>Assessing and profiling aspects of your vehicle (including assessing and predicting faults or issues), driving style (including recommending improvements and assessing risk associated with your driving style), location and routes taken (if relevant to your product or service);</li>
                                                    <li>For direct marketing communications and related profiling to help us to offer you relevant products and services, including deciding whether or not to offer you certain products and services. We’ll send marketing to you by SMS, email, phone, post, social media and digital channels (e.g. using Facebook Custom Audiences and Google Custom Match). Offers may relate to any of our products and services such as cars, roadside assistance, money and financial services, insurance, travel, member offers ("Member Benefits") as well as to any other offers and advice we think may be of interest;</li>
                                                    <li>To provide personalised content and services to you, such as tailoring our products and services, our digital customer experience and offerings, and deciding which offers or promotions to show you on our digital channels;</li>
                                                    <li>To develop new products and services and to review and improve current products and services;</li>
                                                    <li>To comply with legal and regulatory obligations, requirements and guidance;</li>
                                                    <li>To provide insight and analysis of our customers both for ourselves and for the benefit of business partners either as part of providing products or services, helping us improve products or services, or assess or improve the operating of our businesses;</li>
                                                    <li>To share information, as needed, with business partners (e.g. financial services institutions, insurers), account beneficiaries, service providers or as part of providing and administering our products and services or operating our business;</li>
                                                    <li>To facilitate the sale of one or more parts of our business;</li>
                                                    <li>To enable other CSM Driving School group companies to perform any of the above purposes;</li>
                                                    <li>To process any donations made to the CSM Driving School Charitable Trust.</li>
                                                </ul>
                                            </p>
                                        </div>

                                        <div className="mainP">
                                            <h4 className=''>
                                                4. What are the legal grounds for our processing of your personal information (including when we share it with others)?
                                            </h4>
                                            <h4>We rely on the following legal bases to use your personal data: </h4>

                                        </div>
                                        <div className="mainP">
                                            <h4>I. Where it is needed to provide you with our products or services, such as:</h4>
                                            <p className=''>
                                                a. Assessing an application for a product or service you hold with us, including considering whether or not to offer you the product, the price, the payment methods available, and the conditions to attach;
                                            </p>
                                            <p className=''>
                                                b. Managing products and services you hold with us, or an application for one;
                                            </p>
                                            <p className=''>
                                                c. Updating your records, tracing your whereabouts to contact you about your account, and doing this for recovering debt (where appropriate.;
                                            </p>
                                            <p className=''>
                                                d. Sharing your personal information with business partners and service providers when you apply for a product to help manage your product;
                                            </p>
                                            <p className=''>
                                                e. All stages and activities relevant to managing the product or service including enquiry, application, administration and management of accounts, illustrations, requests for transfers of equity, setting up/changing/removing guarantors;
                                            </p>
                                            <p className=''>
                                                f. For some of our profiling and other automated decision-making to decide whether to offer you a product and/or service, particular payment method, and the price or terms of this.
                                            </p>
                                        </div>

                                        <div className="mainP">
                                            <h4>II. Where it is in our legitimate interests to do so, such as:</h4>
                                            <p className=''>
                                                a. Managing your products and services relating to that, updating your records, tracing your whereabouts to contact you about your account and doing this for recovering debt (where appropriate);
                                            </p>
                                            <p className=''>
                                                b. To perform, test the performance of, our products, services and internal processes;
                                            </p>
                                            <p className=''>
                                                c. To follow guidance and recommended best practice of government and regulatory bodies;
                                            </p>
                                            <p className=''>
                                                d. For management and audit of our business operations including accounting;
                                            </p>
                                            <p className=''>
                                                e. To carry out searches at Credit Reference Agencies pre-application or at the application stage;
                                            </p>
                                            <p className=''>
                                                f. To carry out monitoring and to keep records of our communications with you and our staff (see below);
                                            </p>
                                            <p className=''>
                                                g. To administer our good governance requirements and those of other members of our Group, such as internal reporting and compliance obligations or administration required for AGM processes;
                                            </p>
                                            <p className=''>
                                                h. For market research and analysis and developing statistics;
                                            </p>
                                            <p className=''>
                                                i. For direct marketing communications and related profiling to help us to offer you relevant products and services, including deciding whether or not to offer you certain products and services. We’ll send marketing to you by SMS, email, phone, post and social media and digital channels (e.g. using Facebook Custom Audiences and Google Custom Match);
                                            </p>
                                            <p className=''>
                                                j. Subject to the appropriate controls, to provide insight and analysis of our customers to business partners either as part of providing products or services, helping us improve products or services, or to assess or to improve the operating of our businesses;
                                            </p>
                                            <p className=''>
                                                k. For some of our profiling and other automated decision making;
                                            </p>
                                            <p className=''>
                                                l. When we share your personal information with these other people or organisations other than for providing products and services to you, as necessary for running our business or to comply with legal or regulatory obligations.
                                            </p>
                                        </div>

                                        <div className="mainP">
                                            <h4>III. To comply with our legal obligations </h4>

                                        </div>
                                        <div className="mainP">
                                            <h4>IV. With your consent or explicit consent: </h4>
                                            <p className=''>
                                                a. For some direct marketing communications;
                                            </p>
                                            <p className=''>
                                                b. For some of our profiling and other automated decision making;
                                            </p>
                                            <p className=''>
                                                c. For some of our processing of special categories of personal data such as about your health, if you are a vulnerable customer or some criminal records information.
                                            </p>
                                        </div>

                                        <div className="mainP">
                                            <h4>
                                                V. For a public interest, such as:
                                            </h4>
                                            <p>
                                                Processing of your special categories of personal data such as about your health, criminal records information (including alleged offences), or if you are a vulnerable customer.
                                            </p>

                                        </div>

                                        <div className="mainP">
                                            <h4>
                                                V. For a public interest, such as:
                                            </h4>
                                            <p>
                                                Processing of your special categories of personal data such as about your health, criminal records information (including alleged offences), or if you are a vulnerable customer.
                                            </p>

                                        </div>
                                        <div className="mainP">
                                            <h4>5. When do we share your personal information with other organisations?</h4>
                                            <p>
                                                We may share information with the following third parties for the purposes listed above:
                                                <ul>
                                                    <li>CSM Driving School providers;</li>
                                                    <li>Other organisations and businesses who provide services to us such as debt recovery agencies, back-up and server hosting providers, IT software and maintenance providers, document storage providers, and suppliers of other back-office functions;</li>
                                                    <li>Credit Reference and Fraud Prevention Agencies (see below);</li>
                                                    <li>Market research organisations who help us to develop and improve our products and services.</li>
                                                </ul>
                                            </p>
                                        </div>

                                        <div className="mainP">
                                            <h4> 6. How and when can you withdraw your consent?</h4>
                                            <p>
                                                Where we’re relying upon your consent to process personal data, you can withdraw this at any time by contacting us using the contact details in your terms and conditions or going to the Contact Us section of our website.
                                            </p>
                                        </div>
                                        <div className="mainP">
                                            <h4>7. Is your personal information transferred outside Ontario (CA)?</h4>
                                            <p>
                                                We’re based in Ontario (CA), but sometimes your personal information may be transferred Other Provinces and Territories and States. If we do so, we’ll make sure that suitable safeguards are in place, for example by using approved contractual agreements, unless certain exceptions apply.
                                            </p>
                                        </div>
                                        <div className="mainP">
                                            <h4>8. How do we share your information with credit reference agencies?</h4>
                                            <p>
                                                To process your application, we’ll perform credit and identity checks on you with one or more credit reference agencies (CRAs). To do this, we’ll supply your personal information to CRAs and they will give us information about you. This will include information from your credit application and about your financial situation and financial history. CRAs will supply to us both public (including the electoral register) and shared credit, financial situation and financial history information and fraud prevention information.
                                            </p>
                                            <p>
                                                We’ll use this information to:
                                                <ul>
                                                    <li>Assess your creditworthiness and whether you can afford to take the product;</li>
                                                    <li>Verify the accuracy of the data you have provided to us;</li>
                                                    <li>Prevent criminal activity, fraud, and money laundering;</li>
                                                    <li>Manage your account(s);</li>
                                                    <li>Assess payment methods available to you;</li>
                                                    <li>Trace and recover debts;</li>
                                                    <li>Make sure any offers provided to you are appropriate to your circumstances.</li>
                                                </ul>
                                            </p>
                                            <p>
                                                The identities of the CRAs, their role as fraud prevention agencies, the data they hold, the ways in which they use and share personal information, data retention periods and your data protection rights with the CRAs are explained in more detail on our website.
                                            </p>
                                            <p>
                                                When CRAs receive a search from us they will place a search footprint on your credit file that may be seen by other lenders.
                                            </p>
                                            <p>
                                                If you tell us that you have a spouse or financial associate, we’ll link your records together, so you should make sure you discuss this with them, and share with them this information, before lodging the application. CRAs will also link your records together and these links will remain on your and their files until such time as you or your partner successfully files for a disassociation with the CRAs to break that link.
                                            </p>
                                        </div>

                                        <div className="mainP">
                                            <h4>10. What should you do if your personal information changes? </h4>
                                            <p>
                                                You should tell us so that we can update our records. The contact details for this purpose are in your terms and conditions documents. We’ll then update your records if we can.
                                            </p>
                                        </div>

                                        <div className="mainP">
                                            <h4>11. Do you have to provide your personal information to us? </h4>
                                            <p>
                                                We’re unable to provide you with our products or services if you do not provide certain information to us. In cases where providing some personal information is optional, we’ll make this clear.
                                            </p>
                                        </div>
                                        <div className="mainP">
                                            <h4>12. Do we do any monitoring involving processing of your personal information? </h4>
                                            <p>
                                                In this section, monitoring means: any listening to, recording of, viewing of, intercepting of, or taking and keeping records (as the case may be) of calls, email, text messages, social media messages, in person face to face meetings and other communications. We may monitor where permitted by law and we’ll do this where the law requires it, or to comply with regulatory rules, to prevent or detect crime, in the interests of protecting the security of our communications systems and procedures, and for quality control and staff training purposes. This information may be shared for the purposes described above
                                            </p>
                                        </div>
                                        <div className="mainP">
                                            <h4>13. What about other automated decision making? </h4>
                                            <p>
                                                We sometimes make decisions about you using only technology, where none of our employees or any other individuals have been involved. For instance, we may do this to decide: whether to offer you a product or service, to determine the risk of doing so, the price we will offer, whether to offer you credit, what terms and conditions to offer you, assess insurance and business risks, or to assess
                                            </p>
                                            <p>
                                                what payment methods we can offer you. We may also do this using data from other parts of the CSM Driving School, including product or services details (including usage of them or claims made) and telematics data captured including on your vehicle, driving behaviour and location information. We’ll do this where it is necessary for entering into or performing the relevant contract, is authorised by laws that apply to us, or is based on your explicit consent.
                                            </p>
                                        </div>

                                        <div className="mainP">
                                            <h4>14. For how long is your personal information retained by us?</h4>
                                            <p>
                                                Unless we explain otherwise to you, we’ll hold your personal information based on the following criteria:
                                                <ul>
                                                    <li>For as long as we have reasonable business needs, such as managing our relationship with you and managing our operations;</li>
                                                    <li>For as long as we provide goods and/or services to you and then for as long as a claim could be made in relation to these;</li>
                                                    <li>Retention periods in line with legal and regulatory requirements or guidance.</li>
                                                </ul>
                                            </p>
                                        </div>
                                        <div className="mainP">
                                            <h4>15. What are your rights under data protection laws?</h4>
                                            <p>Your rights under data protection laws include:
                                                <ul>
                                                    <li>The right to be informed about your processing of your personal information;</li>
                                                    <li>The right to have your personal information corrected if it is inaccurate and to have incomplete personal information completed;</li>
                                                    <li>The right to object to processing of your personal information;</li>
                                                    <li>The right to restrict processing of your personal information;</li>
                                                    <li>The right to have your personal information erased (the “right to be forgotten”);</li>
                                                    <li>The right to request access to your personal information and to obtain information about how we process it;</li>
                                                    <li>The right to move, copy or transfer your personal information (“data portability”);</li>
                                                    <li>Rights in relation to automated decision-making which has a legal effect or otherwise significantly affects you.</li>
                                                </ul>
                                            </p>
                                        </div>
                                        <div className="mainP">
                                            <h4>16. Your right to object. </h4>
                                            <p>
                                                You have the right to object to certain purposes for processing, in particular to data processed for direct marketing purposes and to data processed for certain reasons based on our legitimate interests. You can contact us using the contact details in your terms and conditions to exercise these rights.

                                            </p>
                                        </div>
                                        <div className="mainP">
                                            <h4>17. What are your marketing preferences and what do they mean? </h4>
                                            <p>
                                                We may use your home address, phone numbers, email address and social media or digital channels (e.g. Facebook, Google and message facilities in other platforms) to contact you according to your marketing preferences.
                                            </p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Terms;