import axios from 'axios';
import React, { useEffect, useState } from 'react';
import ROUTES from '../../navigations/Routes';
import { Link } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router-dom';

function TheoryTest() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const handleTheoryTestClick = (e) => {
    // if (!isLoggedIn) {
    //   e.preventDefault();
    //   navigate('/login');
    // } else {
    //   navigate('test');
    // }
    navigate('test');
  };
  const navigate = useNavigate();
  const [listOfItems, setListOfItems] = useState([]);
  const [editValues, setEditValues] = useState(null);
  const [listOfLocations, setListOfLocations] = useState([]);
  const [listOfImages, setListOfImages] = useState([]);
  const [loggedIn, setLoggedIn] = useState('');

  const { userName } = useParams();
  useEffect(() => {
    const usr = localStorage.getItem('id');
    if (usr) {
      setLoggedIn(usr);
      console.log(usr);
    }
  }, []);
  const onNavigate = (value) => {
    const usr = localStorage.getItem('id');
    setLoggedIn(usr);
    localStorage.setItem('id', usr);
    console.log(value.id);
  };
  useEffect(() => {
    const fetchTheoryTestCategories = async () => {
      try {
        const response = await fetch(
          'https://csmserver.csmdrivingschool.ca/TheoryTestCategories.php'
        );

        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }

        const data = await response.json();
        setListOfItems(data);
        // setListOfImages(Array(data.length).fill(null));
        console.log(data);
      } catch (error) {
        console.error('Error fetching theory test categories:', error);
      }
    };

    fetchTheoryTestCategories();
  }, []);

  const handleEdit = (value) => {
    setEditValues(value);
  };
  useEffect(() => {
    fetch('http://csmserver.csmdrivingschool.ca/CanadaStates.php')
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Network response was not ok.');
        }
      })
      .then((data) => {
        setListOfLocations(data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const handleClick = (location, id) => {
    console.log('Location:', location);
    console.log('ID:', id);
    navigate(
      `/test/${id}?location=${encodeURIComponent(
        location
      )}&id=${encodeURIComponent(id)}`
    );
  };

  const hideModalScreen = () => { };

  return (
    <div className="main">
      <div className="homeContainer">
        <div
          class="modal fade"
          id="detailModal"
          tabindex="-1"
          aria-labelledby="detailModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog locationModal">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title" id="detailModalLabel">
                  List of locations
                </h4>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  style={{ border: 'none', backgroundColor: 'white' }}
                >
                  <i class="bi bi-x-circle" style={{ fontSize: 25 }}></i>
                </button>
              </div>
              <div class="modal-body">
                <div>
                  <div class="row">
                    {listOfLocations.map((value, index) => (
                      <div key={index} class="col-md-3">
                        <div class="d-flex justify-content-between">
                          <b
                            name="location"
                            onClick={() => handleClick(value.location)}
                            style={{ cursor: 'pointer' }}
                          >
                            {value.location}
                          </b>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div class="modal-footer">
                    <div class="detailImageBoxContainer"></div>
                    <button class="btn btn-secondary" data-bs-dismiss="modal">
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid" style={{ paddingTop: 1 }}>
          <div class="untree_co-section bg-white" style={{ marginTop: 90 }}>
            <div className="container-fluid">
              <h1 style={{ color: '#ec661f' }}>
                Smart way to prepare for obtaining a driver's license
              </h1>
              <h3>
                With help of the CSM online practice test, Help you study traffic
                laws and get ready for your driver's license exam.
              </h3>
              <div className="testFeature d-flex">
                <div className="testFeatureDiv">
                  <div className="testFeatureIcon">
                    <i class="fa-solid fa-book-bookmark"></i>
                  </div>
                  <div className="testContent">
                    <h5>Start with interesting quizzes.</h5>
                    <p>
                      Learn how to respond the same types of questions that will
                      be on your exam.
                    </p>
                  </div>
                </div>
                <div className="testFeatureDiv">
                  <div className="testFeatureIcon">
                    <i class="bi bi-question-circle-fill"></i>
                  </div>
                  <div className="testContent">
                    <h5>Learn as you want</h5>
                    <p>
                      Everything you require to know. No time was wasted and no
                      dull lectures.
                    </p>
                  </div>
                </div>
                <div className="testFeatureDiv">
                  <div className="testFeatureIcon">
                    <i class="bi bi-book"></i>
                  </div>
                  <div className="testContent">
                    <h5>Use the Examination Simulator.</h5>
                    <p>
                      In real time, your final results are predicted by a
                      powerful passing probability algorithm.
                    </p>
                  </div>
                </div>
                <div className="testFeatureDiv">
                  <div className="testFeatureIcon">
                    <i class="bi bi-check2"></i>
                  </div>
                  <div className="testContent">
                    <h5>See your pass score</h5>
                    <p>
                      You'll answer the questions with ease when it comes time
                      to take the test.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid testAbout">
              <h2>Driving Test Exam Practice </h2>
              <h5 className="text-justify">
                Whether you're in Ontario striving for your G1 license or aiming
                for a Class 5 to demonstrate your driving proficiency, or in
                Alberta getting ready for the Class 7 to validate your driving
                experience, our specialized practice tests are meticulously
                crafted to empower you with the expertise and assurance required
                to succeed on your initial try. Embark on your path to safe and
                skilled driving now!
              </h5>
            </div>
            <div className="container-fluid testAbout">
              <h2>Questions created to simulate the actual driving test</h2>
              <h4 className="text-black mt-4">Select your province</h4>
              <h5 className="text-justify">
                You should use accurate questions based on your province's
                driver's guide to prepare for traffic laws, as each Canadian
                province and territory has its unique set of rules. Our
                questions closely resemble the actual driving exam because they
                are taken straight from that book.
              </h5>
              <h4 className="text-black mt-4">Up to date and accurate</h4>
              <h5 className="text-justify">
                Our practice exams are always current because they are created
                using the most recent Handbook edition. We also update our
                questions in tandem with any revisions made to the handbook by
                your province or territory. You never work with test data that
                is out of date.
              </h5>
              <h4 className="text-black mt-4">Similar to the actual test</h4>
              <h5 className="text-justify">
                You will be more prepared than if you study solely from the
                handbook because we create our test questions and structure to
                mimic the actual exam.
              </h5>
            </div>

            <div className="row testCategory">
              {listOfItems.map((value, key) => {
                return (
                  <div
                    className="col-lg-5 col-10 py-4 px-1"
                    data-aos="fade-up"
                    data-aos-delay="0"
                    key={key}
                  >
                    <Link className="categoryBox my-2" 
                    to={`/test/${value.id}`}
                    >
                      <div className="categoryname">
                        <h2 style={{ fontSize: 30 }}>{value.categoryname}</h2>
                        <Link
                          to={isLoggedIn ? `/test/${value.id}` : "/login"} // Redirect to login if not logged in
                          state={isLoggedIn ? { testCategoryId: value.id } : null}
                          onClick={(e) => {
                            if (!isLoggedIn) {
                              e.preventDefault();
                              navigate("/login");
                            }
                          }}
                        >
                          Start Free tests
                        </Link>
                      </div>
                      <div className="testImg">
                        <img
                          src={`../${value.image.replace(/\\/g, "/")}`}
                          alt="Questions"
                          className="testImage"
                        />
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>

            {/*  */}
            <div class="card exam">
              <h2 class="mx-3 my-3">Exam Simulator</h2>
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="examImg">
                      <img
                        src="/images/s.jpg"
                        class="img-fluid"
                        alt="Simulator Image"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6 col-12">
                    <div class="px-4">
                      <h3 class="card-title">G1 Exam Simulator </h3>
                      <h5 class="card-text text-justify">
                        Experience the true essence of the G1 exam with our
                        simulator, replicating its format by pulling random
                        questions. With each restart, encounter new questions,
                        devoid of hints or explanations, mirroring the authentic
                        exam environment. Instantly gauge your progress as the
                        simulator halts upon reaching either the passing or
                        failing threshold, providing immediate feedback.
                      </h5>
                      <div class="d-flex justify-content-evenly">
                        <h5 class="mx-2">
                          <span style={{ color: '#ec661f', fontSize: 22 }}>
                            20 random
                          </span>
                          <br /> questions
                        </h5>
                        <h5 class="mx-3">
                          <span style={{ color: '#ec661f', fontSize: 22 }}>
                            4 mistakes
                          </span>
                          <br /> Allowed to pass
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*  */}
          </div>
        </div>
      </div>
    </div>
  );
}
export default TheoryTest;
