import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ROUTES from '../../navigations/Routes';
import { useNavigate } from 'react-router-dom';

function LearnToDrive() {
  const [postCode, setPostCode] = useState('');  // State for holding post code input
  const navigate = useNavigate();
  const [isValid, setIsValid] = useState(null); // Track validation status
  const handleNavigate = () => {
    if (postCode) {
      navigate('/GetPostCode', {
        state: {
          postCode: postCode,
        },
      });
    } else {
      alert('Please enter a post code.');
    }
  };
  const validatePostalCode = (code) => {
    const regex = /^[A-Za-z]\d[A-Za-z][ ]?\d[A-Za-z]\d$/;
    return regex.test(code);
  };
  const handleChange = (e) => {
    const code = e.target.value;
    setPostCode(code);

    if (validatePostalCode(code)) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };
  return (
    <div className="main">
      <div className="homeContainer">
        <div
          class="untree_co-hero inner-page container-fluid TheoryTest"
          style={{
            height: 'auto',
            boxShadow: `rgba(0, 0, 0, 0.35) 0px 5px 15px`,
          }}
        >
          <div class="row align-items-center justify-content-center">
            <div class="col-12" data-aos="fade-up" data-aos-delay="200">
              <div class="row refesherLesson">
                <div class="col-lg-6 col-sm-12">
                  <h2>Learn With CSM</h2>
                  <div class="d-flex ">
                    <div class="Postal">
                      <label class="geoIcon">
                        <i class="bi bi-geo-alt-fill"></i>
                      </label>
                      <input
                        type="text"
                        value={postCode}
                        onChange={handleChange} // Validation logic added here

                        class="form-control pickPostal"
                        placeholder="Pickup Postal Code"
                      ></input>
                    </div>
                    <div class="postalBtn">
                      <button
                        onClick={handleNavigate}
                        class="btn btn-primary"
                        disabled={!isValid}
                      // style={{ height: 45, fontSize: 17 }}
                      >
                        Get Prices
                      </button>
                    </div>
                  </div>
                  {isValid === false && <p style={{ color: 'red' }}>Invalid Postal Code.</p>}
                  {isValid === true && <p style={{ color: 'green' }}>Valid postal code!</p>}
                </div>
                <div
                  class="col-lg-6 d-flex justify-content-end"
                  data-aos="fade-up"
                  data-aos-delay="0"
                >
                  <figure class="img-wrap">
                    <img
                      src="images/instructor.jpg"
                      alt="Image"
                      class="img-fluid"
                    />
                    <div class="dotted"></div>
                  </figure>
                </div>
              </div>

              <div className="slideContainer">
                {/*  */}

                <div className="slideImages">
                  <div
                    className="d-flex flex-wrap justify-content-around align-items-center"
                    style={{
                      position: 'relative',
                      width: '100%',
                      marginTop: 50,
                    }}
                  >
                    <div className="gray-stripe"></div> {/* Gray stripe */}
                    <img
                      src="images/Congratulations.png"
                      style={{
                        paddingTop: '-160px !important',
                        // marginRight: 110,
                      }}
                    ></img>
                    <div className="slideContent">
                      <h5 className="text-center">Easily Book Lessons</h5>
                      <p className="text-center">
                        Book and pay for lessons online in just two minutes
                      </p>
                    </div>
                  </div>
                </div>
                <div className="slideImages">
                  <div
                    className="d-flex flex-wrap justify-content-around align-items-center"
                    style={{
                      position: 'relative',
                      width: '100%',
                    }}
                  >
                    <div className="gray-stripe"></div> {/* Gray stripe */}
                    <img src="images/222.png"></img>
                    <div className="slideContent">
                      <h5 className="text-center">
                        Lessons that fit around you
                      </h5>
                      <p className="text-center">
                        See your chosen instructor's calendar and find a lesson
                        time that suits you.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="slideImages">
                  <div
                    className="d-flex flex-wrap justify-content-around align-items-center"
                    style={{
                      position: 'relative',
                      width: '100%',
                    }}
                  >
                    <div className="gray-stripe"></div> {/* Gray stripe */}
                    <img src="images/3.png" className="ml-3"></img>
                    <div className="slideContent">
                      <h5 className="text-center mr-3">Quality Instructors</h5>
                      <p className="text-center">
                        Select from hundreds of experienced instructors
                        nationwide.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid aboutLearnToDrive bg-white">
          <div data-aos="fade-up" data-aos-delay="0">
            <div class="col-lg-12  d-flex align-items-center justify-content-center ">
              <h2>Learn To Drive With CSM</h2>
            </div>
            <div
              className="justify-content-center py-3 px-3 p"
              style={{ textAlign: 'left' }}
            >
              <p>
                With certified driving instructors available all throughout the
                country, learning to drive with CSM is quite easy. CSM teachers
                undergo intensive training, regular background checks (DBS), and
                drive brand-new, impeccably maintained cars.
              </p>
              <p>
                For most people, the graduated licensing program takes
                approximately 20 to 24 months; however, you have up to five
                years to finish the entire process.
                <br /> If you are approaching five years and you have not yet
                obtained your full Class G license, you may choose to requalify
                as a Class G2 driver for an additional five years by
                successfully completing the Class G2 road test again (road test
                and licensing fees will apply). Once your novice license has
                expired, you may need to start over at the Class G1 level.
              </p>
            </div>
            <div className="learn justify-content-center">
              <div className="learnSection">
                <h4>This is how to get driving</h4>
                <p className="text-black mt-3">
                  The process for obtaining a full Class G license, including
                  the graduated licensing steps, is as follows:
                </p>
                <div className="pointSection">
                  <div className="learnImg">
                    <img src="/images/test.png" height={100} width={100}></img>
                  </div>
                  <div className="keyPoints mx-3">
                    <h4>1. Study for the written knowledge test.</h4>
                    <p>
                      A fantastic online resource for passing the knowledge test
                      on your first try is the{' '}
                      <Link to={ROUTES.theorytest.name}>
                        CSM Knowledge Practice Test.
                      </Link>
                      <span className="ml-1">Another</span> excellent resource
                      is the Official MTO Driver's Handbook for Ontario.
                      Furthermore, the majority of new drivers opt to enroll in
                      a beginner driver education (BDE) course that has been
                      approved by the government. CSM Driving School offers this
                      course via (e-learning / V learning). While not necessary,
                      these courses are advised; however, those who enroll in
                      them can sit for their Class G2 road exam after just eight
                      months—rather than the customary twelve months.{' '}
                    </p>
                  </div>
                </div>
                <div className="pointSection">
                  <div className="learnImg">
                    <img src="/images/2.png" height={100} width={100}></img>
                  </div>
                  <div className="keyPoints mx-3">
                    <h4>2. Pass written test at Drive Test centre.</h4>
                    <p>
                      The knowledge test takes approximately 30 minutes and get
                      your class G1 Driver license. The Class G1 licence is the
                      first of two graduated licensing learning levels prior to
                      getting a full Class G licence.
                    </p>
                  </div>
                </div>

                <div className="pointSection">
                  <div className="learnImg ">
                    <img
                      src="/images/homepage.svg"
                      height={100}
                      width={100}
                    ></img>
                  </div>
                  <div className="keyPoints mx-3">
                    <h4>3. Book a lessons</h4>
                    <p>
                      Super quick and easy with{' '}
                      <Link to={ROUTES.package.name}>CSM online.</Link>
                    </p>
                  </div>
                </div>
                <div className="pointSection">
                  <div className="learnImg">
                    <img
                      src="/images/driving.svg"
                      height={100}
                      width={100}
                    ></img>
                  </div>
                  <div className="keyPoints mx-3 mb-5">
                    <h4>4.Pass your test-Freedom!</h4>
                    <p>
                      Once you have passed, you are free to drive on your own,
                      whenever and wherever you want to go. By law, when you
                      have a class G1 license, few restrictions must be applied.
                    </p>
                  </div>
                </div>
              </div>
              <div className="imgLearnToDrive img-wrap-2">
                <img
                  src="/images/carTest.png"
                  height="400px"
                  width="450px"
                ></img>
              </div>
            </div>
          </div>
        </div>
        {/*  */}
      </div>
    </div>
  );
}
export default LearnToDrive;
