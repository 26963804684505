import React, { Fragment, useEffect, useState } from 'react';
import { Link, Routes, useNavigate } from 'react-router-dom';
import ROUTES from '../navigations/Routes';
import axios from 'axios';
function Navbar({ userName }) {
  const [listOfStudentRequests, setListOfStudentRequests] = useState([]);
  const [loggedInUser, setLoggedInUser] = useState('');
  const [loggedInIns, setLoggedInIns] = useState('');
  const navigate = useNavigate();
  const [userDropdownOpen, setUserDropdownOpen] = useState(false);
  const [bellDropdownOpen, setBellDropdownOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userInitial, setUserInitial] = useState('');
  const handleTheoryTestClick = (e) => {
    // if (!isLoggedIn) {
    //   e.preventDefault();
    //   navigate('/login');
    // } else {
    //   navigate('theoryTest');
    // }
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling effect
    });
    navigate('theoryTest');

  };
  useEffect(() => {
    fetch(
      `https://csmserver.csmdrivingschool.ca/StudentRequest.php?id=${loggedInUser}&notifictation`
    )
      .then((response) => response.json())
      .then((data) => {
        setListOfStudentRequests(data);
        console.log(data);
      })
      .catch((error) =>
        console.error('Error fetching student requests:', error)
      );
  }, [userName]);

  function formatDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
    });
  }

  // useEffect(() => {
  //   // When loggedInUser state changes, update userInitial state
  //   setUserInitial(getInitial(loggedInUser || loggedInIns));
  // }, [loggedInUser, loggedInIns]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling effect
    });
  };

  useEffect(() => {
    // Check local storage for logged-in user data
    const userName = localStorage.getItem('currentUserName');
    const insName = localStorage.getItem('currentInsName');
    if (userName) {
      setLoggedInUser(userName);
    }
    if (insName) {
      setLoggedInIns(insName);
    }
  }, []);

  const handleUserDropdownClick = () => {
    setUserDropdownOpen(!userDropdownOpen);
    setBellDropdownOpen(false); // Close the bell dropdown
  };

  const handleBellDropdownClick = () => {
    setBellDropdownOpen(!bellDropdownOpen);
    setUserDropdownOpen(false); // Close the user dropdown
  };
  const handleLogout = () => {
    const confirmLogout = window.confirm('Are you sure you want to logout?');
    if (confirmLogout) {
      localStorage.removeItem('currentUserName'); // Assuming 'currentUserName' is used for storing username
      localStorage.removeItem('currentInsName');
      setLoggedInUser(null); // Reset loggedInUser state
      setLoggedInIns(null);
      setUserDropdownOpen(false); // Close the dropdown
      navigate('/');
    }
  };
  const getInitial = (userName) => {
    return userName ? userName.charAt(0).toUpperCase() : '';
  };

  return (
    <div>
      <div className="site-mobile-menu">
        <div className="site-mobile-menu-header">
          <div className="site-mobile-menu-close">
            <span className="icofont-close js-menu-toggle"></span>
          </div>
        </div>
        <div className="site-mobile-menu-body"></div>
      </div>
      <nav className="site-nav mb-5">
        <div className="topBar"></div>
        <div className="top-bar py-3">
          <div className="container-xl px-5 px-lg-0">
            <div className="row text-right justify-center">
              <div className="col-lg-6 d-flex justify-content-start">
                <a href="#" className="logo pl-5 pl-lg-5">
                  <img src="../../images/CSM2.png"></img>
                </a>
              </div>
              <div className="col-12 col-lg-6 d-none d-lg-block">
                <div className="d-flex flex-wrap justify-content-end ">
                  <div className="row">
                    <a href="#" className="small text-white fixAncc col-6">
                      <span className="mr-0">
                        <i class="bi bi-telephone text-white mx-2"></i>
                      </span>
                      <span className="d-none d-inline-block text-white">
                        1 647 719-3526
                      </span>
                    </a>
                    <a href="#" className="fixAnc small text-white col-6">
                      <span className="">
                        Open from: 9:00 am to 5:00 pm
                      </span>
                    </a>
                  </div>
                  <div className="row text-start">
                    <a href="#" className="fixAncc small text-white col-6">
                      <span className="mx-1">
                        <i class="fa-solid fa-fax"></i>
                      </span>
                      <span className="d-lg-inline-block ml-1 text-white">
                        647 490-0094
                      </span>
                    </a>
                    <div class="fixAnc small col-6 text-white">
                      {/* <div class="outerInput">
                        <input
                          class="form-control whitePlaceholder"
                          type="search"
                          placeholder="Search Location"
                          aria-label="Search"
                        />
                        <button class="innerBtn" type="submit">
                          <i class="bi bi-search"></i>
                        </button>
                      </div> */}
                      <span className="mr-1">
                        <i class="fa-solid fa-envelope"></i>
                      </span>
                      <span className="d-lg-inline-block text-white">
                        csmdrivingschool@outlook.com
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <a href="#" className="fixAncc small  text-white col-6">
                      <span className="mx-1">
                        <i class="fa-solid fa-headset"></i>
                      </span>
                      <span className="d-none d-inline-block text-white">
                        (886)791-9291
                      </span>
                    </a>
                    <a href="#" className="fixAnc small text-white col-6">
                      
                    </a>
                  </div>
                </div>
              </div>
              <a
                href="#"
                className="burger ml-auto float-right site-menu-toggle js-menu-toggle d-inline-block d-lg-none"
                data-toggle="collapse"
                data-target="#main-navbar"
              >
                <span></span>
              </a>
            </div>
          </div>
        </div>
        <div className="sticky-nav js-sticky-header">
          <div className="my-2 position-relative">
            <div className="site-navigation text-center ownPnn">
              <ul className="js-clone-nav d-none d-lg-flex site-menu displayS">
                <li>
                  <Link to={ROUTES.home.name} onClick={scrollToTop}>Home </Link>
                </li>
                <li class="has-children">
                  <Link>Learn To Drive</Link>
                  <ul class="dropdown">
                    <li>

                      <Link to={ROUTES.learntodrive.name} onClick={scrollToTop}>Driving Lessons</Link>
                    </li>
                    <li>
                      <Link to={ROUTES.obtainLicense.name} onClick={scrollToTop}>
                        Obtain A License
                      </Link>
                    </li>

                    <li>
                      <Link to={ROUTES.guideToDrivingTest.name} onClick={scrollToTop}>
                        Guide to the driving theory test
                      </Link>
                    </li>
                    <li>
                      <Link to={ROUTES.guideToPracticalTest.name} onClick={scrollToTop}>
                        Guide to the practical driving test
                      </Link>
                    </li>
                    <li>
                      <Link to={ROUTES.refresherLessons.name} onClick={scrollToTop}>
                        Refresher Lessons
                      </Link>
                    </li>
                    <li>
                      <Link to={ROUTES.femaleInstructor.name} onClick={scrollToTop}>
                        Female Driving Instructor
                      </Link>
                    </li>
                    <li>
                      <Link to={ROUTES.learnerDriverInsurance.name} onClick={scrollToTop}>
                        Learner Driver Insurance
                      </Link>
                    </li>
                    <li>
                      <Link to={ROUTES.advanceDrivingCourse.name} onClick={scrollToTop}>
                        Advance Driving Courses
                      </Link>
                    </li>
                    <li>
                      <Link to={ROUTES.guideToBDE.name} onClick={scrollToTop}>
                        Guide To Beginner Driver Course
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link
                    to={ROUTES.theorytest.name}
                    onClick={handleTheoryTestClick}
                  >
                    Theory Test
                  </Link>
                </li>
                <li>
                  <Link to={ROUTES.package.name} onClick={scrollToTop}>Choose Your Package</Link>
                </li>
                <li>
                  <Link to={ROUTES.login.name} onClick={scrollToTop}>Student Login</Link>
                </li>
                <li>
                  <Link to={ROUTES.instructorLogin.name} onClick={scrollToTop}>Instructor Login</Link>
                </li>
                {/* <li>
                  <Link to={ROUTES.joinOurFleet.name}>Join Our Fleet</Link>
                </li> */}
                <li>
                  <Link to={ROUTES.instructor.name} onClick={scrollToTop}>
                    Become a Driving Instructor
                  </Link>
                </li>

                <ul
                  className="btn btn-enroll list-unstyled has-children"
                  style={{ position: 'absolute', top: 0, right: 0 }}
                >
                  {loggedInUser || loggedInIns ? (
                    <li style={{ display: 'flex' }}>
                      <div
                        className="bellIcon"
                        onClick={handleBellDropdownClick}
                      >
                        <i
                          className="bi bi-bell-fill"
                          style={{
                            fontSize: 25,
                            marginRight: '10px',
                            position: 'relative',
                          }}
                        >
                          <span
                            className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
                            style={{
                              fontSize: 10,
                              top: 0,
                              right: 0,
                              transform: 'translate(50%, -50%)',
                            }}
                          >
                            +{listOfStudentRequests.length}
                          </span>
                        </i>
                      </div>
                      <div
                        className="welcomeUser"
                        onClick={handleUserDropdownClick}
                      >
                        <div className="userInitialCircle">
                          {' '}
                          {loggedInUser
                            ? getInitial(loggedInUser)
                            : getInitial(loggedInIns)}
                        </div>
                      </div>
                      {userDropdownOpen && (
                        <div
                          className="dropdownMenu userDropdown"
                          style={{ top: '50px' }}
                        >
                          <div className="userDetails">
                            <div className="userInitialCircle large">
                              {loggedInUser
                                ? getInitial(loggedInUser)
                                : getInitial(loggedInIns)}
                            </div>
                            <div className="greeting">
                              Hi, {loggedInUser || loggedInIns}!
                            </div>
                          </div>
                          <div className="dropdownItem">
                            <Link
                              onClick={scrollToTop}
                              to={
                                loggedInIns
                                  ? ROUTES.instructorPanel.name
                                  : ROUTES.student.name
                              }
                            >
                              Go to profile
                            </Link>
                          </div>
                          <div
                            className="dropdownItem logoutButton"
                            onClick={handleLogout}
                          >
                            <i className="fa-solid fa-power-off"></i>Log out
                          </div>
                        </div>
                      )}
                      {bellDropdownOpen && (
                        <ul
                          className="dropdownMenu"
                          style={{
                            top: '50px',
                            right: '60px',
                            listStyle: 'none',
                          }}
                        >
                          {/* Bell dropdown content */}
                          {Array.isArray(listOfStudentRequests) &&
                            listOfStudentRequests.length > 0 ? (
                            listOfStudentRequests.map((value, index) => (
                              <li key={index}>
                                <div
                                  className="notification-item"
                                  style={{
                                    backgroundColor: value.isInstructorNotif
                                      ? '#EDECEC'
                                      : '#FFFFFF',
                                  }}
                                >
                                  <div className="username">
                                    {value.studentUserName}
                                  </div>
                                  <div className="text py-1">
                                    <b>{value.studentUserName}</b> has requested
                                    for session{' '}
                                  </div>
                                  <div className="textSession py-1">
                                    Date: <b>{value.schedule.sessionDate}</b>{' '}
                                    Time: <b>{value.schedule.sessionTime}</b>
                                  </div>
                                  <div className="date py-2">
                                    Requested On: {value.createdAt}
                                  </div>
                                </div>
                              </li>
                            ))
                          ) : (
                            <li>No student requests available.</li>
                          )}
                          <li style={{ listStyle: 'none' }}>
                            <Link
                              onClick={scrollToTop}
                              to={`${ROUTES.notificationList.name}/${loggedInUser || loggedInIns
                                }`}
                            >
                              View All
                            </Link>
                          </li>
                        </ul>
                      )}
                    </li>
                  ) : (
                    <li>
                      <button
                        onClick={() => navigate('/login')}
                        className="navLogIn"
                      >
                        <i className="fa-solid fa-user mx-1"></i>Login
                      </button>
                    </li>
                  )}
                </ul>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}
export default Navbar;
